<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t('message.new_warehouse') }}</span>
      <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit(true)">{{ $t('message.save_and_close')  }}</el-button>
      <el-button
        type="warning"
        icon="el-icon-close"
        size="small"
        @click="close()"
      >{{ $t('message.close')  }}</el-button>
    </header>
    <div class="body__modal">
      <el-form ref="form" :model="form" :rules="rules" size="small" class="aticler_m stylekhanForm">
        <el-row :gutter="20">
           <el-col :span="12">
             <el-form-item :label="$t('message.department')" prop="branch">
                  <branch v-model="form.branch_id" :branch_id="form.branch_id" size="mini"></branch>
              </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item :label="columns.name.title">
              <el-input v-model="form.name" :placeholder="columns.name.title" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import drawerChild from "@/utils/mixins/drawer-child";
import branch from '@/components/selects/branch';
import form from "@/utils/mixins/form";

export default {
  mixins: [
    form,
    drawerChild
  ],
  components:{
    branch
  },
  data(){
    return {
          loadingData: false
    }
  },
  props: ['selected'],
  computed: {
    ...mapGetters({
      columns: 'warehouses/columns',
      model: 'warehouses/model',
      rules: 'warehouses/rules'
    })
  },
  methods: {
    ...mapActions({
      save: "warehouses/store",
      update: "warehouses/update",
      editModel: "warehouses/show",
      empty: "warehouses/empty"
    }),
    afterOpened(){
        if (this.selected && !this.loadingData) {
            this.loadingData = true;
            this.editModel(this.selected.id)
                .then(async (res) => {
                    this.loadingData = false;
                    this.form = JSON.parse( JSON.stringify(this.model) );
                })
                .catch(err => {
                    this.loadingData = false;
                    this.$alert(err)
                });                    
        }
    },
    afterClosed(){
      this.empty()
    },
    submit(close = true) {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loadingButton = true;
            this.update(this.form)
              .then(res => {
                  this.loadingButton = false;
                  this.$alert(res);
                  this.parent().listChanged()
                  if (close == true) {
                      this.close();
                  }
              })
              .catch(err => {
                  this.loadingButton = false;
                  this.$alert(err);
              })
        }
      });
    },
  }
};
</script>
